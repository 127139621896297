// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NAVBAR STYLING STARTS */
.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  background-color: #2884D8;
  color: #fff;
  }
  
  /* LOGO */
.logo {
    font-size: 32px;  
  }

.logo img {
    height: 70px; /* Adjust the height as needed */
    width: auto; /* Maintain aspect ratio */
    padding-right: 10px; /* Add some space to the right of the logo */
}
  
  /* NAVBAR MENU */
.menu {
  display: flex;
  gap: 1em;
  font-size: 18px;
}

.nav-link {
  text-decoration: none;
  color: white;
}

.nav-link:hover {
  text-decoration: none;
  color: #2884D8;
}

.menu li:hover {
  background-color: white;
  border-radius: 5px;
  transition: 0.3s ease;
}

.menu li {
  padding: 5px 14px;
  list-style-type: none;
  color: white;
}

.dropdown li + li {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/NavBar.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,yBAAyB;EACzB,WAAW;EACX;;EAEA,SAAS;AACX;IACI,eAAe;EACjB;;AAEF;IACI,YAAY,EAAE,gCAAgC;IAC9C,WAAW,EAAE,0BAA0B;IACvC,mBAAmB,EAAE,4CAA4C;AACrE;;EAEE,gBAAgB;AAClB;EACE,aAAa;EACb,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["/* NAVBAR STYLING STARTS */\n.navbar {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-top: 70px;\n  background-color: #2884D8;\n  color: #fff;\n  }\n  \n  /* LOGO */\n.logo {\n    font-size: 32px;  \n  }\n\n.logo img {\n    height: 70px; /* Adjust the height as needed */\n    width: auto; /* Maintain aspect ratio */\n    padding-right: 10px; /* Add some space to the right of the logo */\n}\n  \n  /* NAVBAR MENU */\n.menu {\n  display: flex;\n  gap: 1em;\n  font-size: 18px;\n}\n\n.nav-link {\n  text-decoration: none;\n  color: white;\n}\n\n.nav-link:hover {\n  text-decoration: none;\n  color: #2884D8;\n}\n\n.menu li:hover {\n  background-color: white;\n  border-radius: 5px;\n  transition: 0.3s ease;\n}\n\n.menu li {\n  padding: 5px 14px;\n  list-style-type: none;\n  color: white;\n}\n\n.dropdown li + li {\n  padding-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
