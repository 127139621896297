// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  background-color: #2884D8; 
  min-height: 100vh; /* Use min-height instead of height */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0; /* Ensure there's no default margin */
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB,EAAE,qCAAqC;EACxD,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,SAAS,EAAE,qCAAqC;AAClD;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".App {\n  background-color: #2884D8; \n  min-height: 100vh; /* Use min-height instead of height */\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  margin: 0; /* Ensure there's no default margin */\n}\n\n.center-content {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
