// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: #2884D8;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 50px;
  padding-top: 100px;
 }
 
@media (max-width: 576px) {
  footer {
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
  }
}
 
@media (min-width: 576px) {
  footer {
    flex-direction: row;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
  }
}

footer p {
  text-align: center;
}

footer a {
  color: white;
  text-decoration: none;
}

img { 
    height: 40px; /* Reduce the logo size on smaller screens */
    width: auto; /* Maintain aspect ratio */
    padding-right: 5px; /* Adjust space to the right of the logo */
    padding-bottom: 10px;
} 
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;CACnB;;AAED;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;EACnB;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;IACI,YAAY,EAAE,4CAA4C;IAC1D,WAAW,EAAE,0BAA0B;IACvC,kBAAkB,EAAE,0CAA0C;IAC9D,oBAAoB;AACxB","sourcesContent":["footer {\n  background-color: #2884D8;\n  color: white;\n  display: flex;\n  justify-content: space-between;\n  padding: 20px;\n  align-items: center;\n  padding-left: 70px;\n  padding-right: 70px;\n  padding-bottom: 50px;\n  padding-top: 100px;\n }\n \n@media (max-width: 576px) {\n  footer {\n    flex-direction: column;\n    align-items: center;\n    padding-left: 20px;\n    padding-right: 20px;\n    padding-top: 50px;\n  }\n}\n \n@media (min-width: 576px) {\n  footer {\n    flex-direction: row;\n    align-items: center;\n    padding-left: 50px;\n    padding-right: 50px;\n    padding-top: 50px;\n  }\n}\n\nfooter p {\n  text-align: center;\n}\n\nfooter a {\n  color: white;\n  text-decoration: none;\n}\n\nimg { \n    height: 40px; /* Reduce the logo size on smaller screens */\n    width: auto; /* Maintain aspect ratio */\n    padding-right: 5px; /* Adjust space to the right of the logo */\n    padding-bottom: 10px;\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
