// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coming-soon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px;
}

.coming-soon-title {
  font-size: 14vw; /* Adjust the value as needed */
  color: white; /* A vibrant color for the title */
  padding-bottom: 20px; /* Space between the title and text */
}

.coming-soon-text {
  font-size: 5vw; /* Larger font size for the text */
  color: white; /* Darker color for the text */
  text-align: center; /* Center the text */
}
 /* Optional: Add a subtle animation for the title */
@keyframes pulse {
  0% {
    transform: scale(1);
}
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.coming-soon-title {
  animation: pulse 10s infinite; /* Animate the title */
}
`, "",{"version":3,"sources":["webpack://./src/components/ComingSoon.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe,EAAE,+BAA+B;EAChD,YAAY,EAAE,kCAAkC;EAChD,oBAAoB,EAAE,qCAAqC;AAC7D;;AAEA;EACE,cAAc,EAAE,kCAAkC;EAClD,YAAY,EAAE,8BAA8B;EAC5C,kBAAkB,EAAE,oBAAoB;AAC1C;CACC,mDAAmD;AACpD;EACE;IACE,mBAAmB;AACvB;EACE;IACE,qBAAqB;EACvB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,6BAA6B,EAAE,sBAAsB;AACvD","sourcesContent":[".coming-soon-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 70px;\n}\n\n.coming-soon-title {\n  font-size: 14vw; /* Adjust the value as needed */\n  color: white; /* A vibrant color for the title */\n  padding-bottom: 20px; /* Space between the title and text */\n}\n\n.coming-soon-text {\n  font-size: 5vw; /* Larger font size for the text */\n  color: white; /* Darker color for the text */\n  text-align: center; /* Center the text */\n}\n /* Optional: Add a subtle animation for the title */\n@keyframes pulse {\n  0% {\n    transform: scale(1);\n}\n  50% {\n    transform: scale(1.1);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n\n.coming-soon-title {\n  animation: pulse 10s infinite; /* Animate the title */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
